<template>
  <Teleport to="body">
    <Transition
      :css="false"
      @leave="
        (el, done) => {
          motions['my_modal'].leave(done);
          motions['my_overlay'].leave(done);
        }
      "
    >
      <div
        @keydown.escape.stop="close"
        v-if="modelValue"
        class="pointer-events-auto top-0 fixed h-full w-full z-[9999] grid place-content-center"
      >
        <!-- Overlay -->
        <div
          class="absolute top-0 left-0 w-full h-full bg-white/80"
          @click="close"
          v-motion="`my_overlay`"
          :initial="{
            opacity: 0,
          }"
          :enter="{
            opacity: 1,
            transition: {
              duration: 150,
            },
          }"
          :leave="{
            opacity: 0,
            transition: {
              duration: 150,
            },
          }"
        />
        <!-- content -->
        <div
          class="relative z-10 w-full max-w-[calc(100dvw-16px)] mx-auto bg-white p-4 rounded-[20px] border-2 border-accent bottom-0"
          :class="{
            '!p-0': props.noPadding,
            '!max-w-full': props.full,
            'md:max-w-[600px]': !props.full,
            '!overflow-hidden': props.noOverflow,
            '!rounded-none': props.noRounding,
          }"
          v-motion="`my_modal`"
          :initial="{
            opacity: 0.5,
            y: 200,
          }"
          :leave="{
            opacity: 0,
            y: 300,
            transition: {
              duration: 150,
            },
          }"
          :enter="{
            opacity: 1,
            y: 0,
            transition: {
              type: 'spring',
              stiffness: 400,
              damping: 40,
            },
          }"
        >
          <slot />

          <button
            v-if="showCloseButton || (!persistent && !props.nonClosable)"
            @click="close"
            class="absolute -right-2 -top-5 bg-white size-[38px] rounded-full border-2 border-accent grid place-content-center transition-all"
            :class="{
              '!right-2 !top-6 !size-[32px]': props.closeInside
            }"
          >
            <v-icon name="hi-x" />
          </button>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import { onKeyStroke } from '@vueuse/core'
import { useMotions } from "@vueuse/motion";

interface ModalProps {
  modelValue: boolean | undefined;
  persistent?: boolean;
  full?: boolean;
  nonClosable?: boolean;
  showCloseButton?: boolean;
  closeInside?: boolean;
  noPadding?: boolean;
  noOverflow?: boolean;
  noRounding?: boolean;
}

const props = defineProps<ModalProps>();
const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
}>();


watch(
  () => props.modelValue,
  (value) => {
    if (value) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }
);

onBeforeUnmount(() => {
  emit("update:modelValue", false);
  document.body.style.overflow = "auto";
});

onBeforeRouteLeave(() => {
  emit("update:modelValue", false);
  document.body.style.overflow = "auto";
});

const close = () => {
  if (props.persistent) return;
  emit("update:modelValue", false);
};

onKeyStroke("Escape", close); 
const motions = useMotions();
</script>
